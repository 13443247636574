import { createPersistentStore } from '@/lib/utils';
import { Report } from '@/types/report';
import { useSelectedItemStore } from './selectedItemStore';
import { api } from './authStore';
import { toast } from 'sonner';
import { useSearchStore } from './searchStore';
import { useMemo } from 'react';

export const REPORT_ALIASES = ['reports', 'letters']

interface PaginationState {
  total: number;
  page: number;
  limit: number;
  totalPages: number;
}

interface ReportStore {
  reports: Report[];
  isLoading: boolean;
  error: string | null;
  searchQuery: string;
  searchDebounceTimeout: NodeJS.Timeout | null;
  pagination: PaginationState | null;

  setSearchQuery: (query: string) => void;

  updateTitle: (reportId: number, newTitle: string) => Promise<void>;
  updateDescription: (reportId: number, newDescription: string) => Promise<void>;

  fetchReports: () => Promise<void>;
  generateReport: () => Promise<Report | null>;
  regenerateReport: (reportId: number) => Promise<void>;
  retry: () => Promise<void>;
}

// TODO: fixme use a library for caching loading error state etc...
export const useReportStore = createPersistentStore<ReportStore>('reportData', (set, get) => ({
  reports: [],
  isLoading: false,
  error: null,
  searchQuery: '',
  searchDebounceTimeout: null,
  pagination: null,
  
  setSearchQuery: (query) => {
    set({ searchQuery: query });
    const searchDebounceTimeout = get().searchDebounceTimeout;
    if (searchDebounceTimeout) clearTimeout(searchDebounceTimeout);
    const timeout = setTimeout(() => {
      get().fetchReports();
    }, 300);
    set({ searchDebounceTimeout: timeout });
  },

  fetchReports: async () => {
    set({ isLoading: true, error: null });
    try {
      const { searchQuery } = get();
      const response = await api.get('/protected/report/list', {
        params: {
          query: searchQuery,
        },
      });
      set({
        reports: response.data.reports,
        pagination: response.data.pagination,
        isLoading: false
      });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred fetching Reports';
      set({
        error: errorMessage,
        isLoading: false
      });
      toast.error(errorMessage);
    }
  },
  
  generateReport: async () => {
    const selectedItems = useSelectedItemStore.getState().selectedItems;
    if (selectedItems.length === 0) {
      toast.error('No items selected');
      return null;
    }
    try {
      const response = await api.post('/protected/report/generate', { items: selectedItems });
      await get().fetchReports();
      return response.data;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred generating report';
      toast.error(errorMessage);
      return null;
    }
  },
  
  regenerateReport: async (reportId: number) => {
    try {
      const response = await api.post(`/protected/report/${reportId}/regenerate`);
      set((state) => ({
        reports: state.reports.map((report) => (report.id === reportId ? response.data : report))
      }));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred regenerating report';
      toast.error(errorMessage);
    }
  },
  
  updateDescription: async (reportId: number, newDescription: string) => {
    try {
      const response = await api.put(`/protected/report/${reportId}/updateDescription`, {
        description: newDescription
      });
      if (response.status === 200) {
        set((state) => ({
          reports: state.reports.map((report) =>
            report.id === reportId ? { ...report, description: newDescription } : report
          )
        }));
      } else {
        toast.error('Failed to update report description');
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred updating report description';
      toast.error(errorMessage);
    }
  },
  
  updateTitle: async (reportId: number, newTitle: string) => {
    try {
      const response = await api.put(`/protected/report/${reportId}/updateTitle`, { title: newTitle });
      if (response.status === 200) {
        set((state) => ({
          reports: state.reports.map((report) => (report.id === reportId ? { ...report, title: newTitle } : report))
        }));
      } else {
        toast.error('Failed to update report title');
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred updating report title';
      toast.error(errorMessage);
    }
  },

  retry: async () => await get().fetchReports(),
}));

export const useFilteredReports = () => {
  const { reports } = useReportStore();
  const { filters } = useSearchStore();

  return useMemo(() => {
    const lowerInclude = filters.include?.toLowerCase();
    const lowerExclude = filters.exclude?.toLowerCase();
    const afterDate = filters.after ? new Date(filters.after) : null;
    const beforeDate = filters.before ? new Date(filters.before) : null;
    const filterTab = filters.tab?.toLowerCase();

    return reports.filter((report) => {
      const title = report.title?.toLowerCase() ?? '';
      const description = report.description?.toLowerCase() ?? '';
      const createdAt = new Date(report.createdAt);

      if (filterTab && filterTab !== '' && !REPORT_ALIASES.some((alias) => alias.toLowerCase().includes(filterTab))) {
        return false;
      }

      if (lowerInclude && !title.includes(lowerInclude) && !description.includes(lowerInclude)) {
        return false;
      }

      if (lowerExclude && (title.includes(lowerExclude) || description.includes(lowerExclude))) {
        return false;
      }

      if (afterDate && createdAt < afterDate) {
        return false;
      }

      if (beforeDate && createdAt > beforeDate) {
        return false;
      }

      return true;
    });
  }, [reports, filters]); 
  
};
