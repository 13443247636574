import { create } from 'zustand';
import axios from 'axios';
import Cookies from 'js-cookie';
import { UserType, tabsConfig } from '@/lib/categories';
import { TabConfig } from '@/types/display'
import { useSearchStore } from './searchStore';
import { useProjectStore } from './projectStore';
import { useReportStore } from './reportStore';
import { useInspectionStore } from './inspectionStore';
import { useObservationStore } from './observationStore';
import { useRecordingStore } from './recordingStore';
import { usePhotoStore } from './photoStore';

interface User {
  id: string;
  username: string;
  email: string;
  user_type: UserType;
  createdAt: Date;
  updatedAt: Date;
}

interface AuthState {
  user: User | null;
  userInitialised: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  loading: boolean;
  error: string | null;
  refreshTimeout: NodeJS.Timeout | null;
  userTabs: TabConfig[];
  defaultTab: TabConfig | null;
  initialiseUser: () => Promise<void>;
  initialiseData: () => Promise<void>;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => Promise<void>;
  checkAuth: () => Promise<void>;
  refresh: () => Promise<void>;
}

const ACCESS_TOKEN_COOKIE = 'access_token';
const REFRESH_TOKEN_COOKIE = 'refresh_token';

export const api = axios.create({
  baseURL: '/api'
});

api.interceptors.request.use((config) => {
  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE);
  if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const useAuthStore = create<AuthState>((set, get) => ({
  user: null,
  userInitialised: false,
  refreshTimeout: null,
  accessToken: Cookies.get(ACCESS_TOKEN_COOKIE) || null,
  refreshToken: Cookies.get(REFRESH_TOKEN_COOKIE) || null,
  loading: true,
  error: null,
  userTabs: tabsConfig[UserType.MEDICAL],

  get defaultTab() {
    const { userTabs, userInitialised } = get();
    if (!userInitialised) {
      console.warn('Attempted to access defaultTab before userInitialised');
      return null;
    }
    const defaultTab = userTabs.find((tab) => tab.isDefault) || null;
    return defaultTab;
  },

  initialiseUser: async () => {
    const { user } = get();
    try {
      if (!user) {
        throw new Error('User is null');
      }
      const userTabs = tabsConfig[user.user_type];
      const defaultTab = userTabs.find((tab) => tab.isDefault) || null;
      set({ userTabs, defaultTab, userInitialised: true });
    } catch (error) {
      console.error('Error initializing user:', error);
      set({ userInitialised: false });
    }
  },

  initialiseData: async () => {
    const { initialiseSearch } = useSearchStore.getState();
    const { fetchProjects } = useProjectStore.getState();
    const { fetchReports } = useReportStore.getState();
    const { fetchInspections } = useInspectionStore.getState();
    const { fetchObservations } = useObservationStore.getState();
    const { fetchRecordings } = useRecordingStore.getState();
    const { fetchPhotos } = usePhotoStore.getState();
    const { defaultTab } = get();

    try {
      await Promise.all([
        initialiseSearch(defaultTab),
      ]);
    } catch (error) {
      console.error('Error initialising search:', error);
    }

    try {
      await Promise.all([
        fetchProjects(),
        fetchReports(),
        fetchInspections(),
        fetchObservations(),
        fetchRecordings(),
        fetchPhotos(),
      ]);
    } catch (error) {
      console.error('Error initialising data:', error);
    }
  },

  login: async (email: string, password: string) => {
    try {
      const response = await api.post('/auth/login', { email, password });
      const { user, accessToken, refreshToken } = response.data;
      // FIXME: expiry from jwt
      const accessTokenExpiry = new Date();
      accessTokenExpiry.setMinutes(accessTokenExpiry.getMinutes() + 30);
      Cookies.set(ACCESS_TOKEN_COOKIE, accessToken, {
        expires: accessTokenExpiry,
        path: '/'
      });
      Cookies.set(REFRESH_TOKEN_COOKIE, refreshToken, {
        expires: 30,
        path: '/'
      });
      const refreshTimeout = setTimeout(() => {
        get().refresh();
      }, 29 * 60 * 1000); // FIXME: read jwt. backend does refresh every 30m. call this every 29m
      set({
        user,
        accessToken,
        refreshToken,
        error: null,
        refreshTimeout,
      });
      if (response.data) {
        await get().initialiseUser();
        await get().initialiseData();
      };
      return true;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      set({ error: 'Invalid login credentials' });
      return false;
    }
  },

  logout: async () => {
    try {
      const timeout = get().refreshTimeout;
      if (timeout) clearTimeout(timeout);
      await api.post('/auth/logout');
    } catch (error) {
      console.error('Logout failed', error);
    } finally {
      Cookies.remove(ACCESS_TOKEN_COOKIE);
      Cookies.remove(REFRESH_TOKEN_COOKIE);
      set({
        user: null,
        accessToken: null,
        refreshToken: null,
        error: null,
        refreshTimeout: null
      });
    }
  },

  checkAuth: async () => {
    set({ loading: true });
    try {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE);
      const refreshToken = Cookies.get(REFRESH_TOKEN_COOKIE);
      if (accessToken) {
        const response = await api.get('/auth/user');
        set({ user: response.data, accessToken, error: null });
        await get().initialiseUser();
        await get().initialiseData();
      } else if (refreshToken) {
        await get().refresh();
      } else {
        set({ user: null, accessToken: null, refreshToken: null });
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      Cookies.remove(ACCESS_TOKEN_COOKIE);
      Cookies.remove(REFRESH_TOKEN_COOKIE);
      set({ user: null, accessToken: null, refreshToken: null });
    } finally {
      set({ loading: false });
    }
  },

  refresh: async () => {
    try {
      const refreshToken = Cookies.get(REFRESH_TOKEN_COOKIE);
      if (!refreshToken) return;
      const response = await api.post('/auth/refresh', { refreshToken });
      const { accessToken: newAccessToken, refreshToken: newRefreshToken } = response.data;
      // FIXME: expiry from jwt
      const accessTokenExpiry = new Date();
      accessTokenExpiry.setMinutes(accessTokenExpiry.getMinutes() + 30);
      Cookies.set(ACCESS_TOKEN_COOKIE, newAccessToken, {
        expires: accessTokenExpiry,
        path: '/'
      });
      Cookies.set(REFRESH_TOKEN_COOKIE, newRefreshToken, {
        expires: 30,
        path: '/'
      });
      set({
        accessToken: newAccessToken,
        refreshToken: newRefreshToken,
        error: null
      });
    } catch (error) {
      console.error('Error refreshing token:', error);
      const timeout = get().refreshTimeout;
      if (timeout) clearTimeout(timeout);
      set({
        user: null,
        accessToken: null,
        refreshToken: null,
        error: 'Failed to refresh token',
        refreshTimeout: null
      });
      Cookies.remove(ACCESS_TOKEN_COOKIE);
      Cookies.remove(REFRESH_TOKEN_COOKIE);
    }
  },

}));
