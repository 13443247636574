import { Navigate, useOutletContext, useParams } from 'react-router-dom';
import { useMediaStore } from '@/store/mediaStore';
import { usePhotoStore } from '@/store/photoStore';
import { useEffect, useState } from 'react';
import { DetailsOutletContext } from '@/layouts/LayoutDetails';
import { Button } from '@/components/ui/button';
import { RefreshCcw } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Textarea } from '@/components/ui/textarea';
import { DashboardOutletContext } from '@/layouts/Layout';

export function PhotoDetails() {
  const [isRegenerating, setIsRegenerating] = useState(false);
  const { regeneratePhotoSummary } = useMediaStore();
  const params = useParams();
  if (isNaN(Number(params.id))) return <Navigate to="/dashboard" />;
  const photo = usePhotoStore((state) => state.photos).find((m) => m.id === Number(params.id));
  if (!photo) return <Navigate to="/dashboard" />;

  const { setScreen } = useOutletContext<DashboardOutletContext>();

  useEffect(() => {
    setScreen({ screen: 'photo', id: Number(params.id) });
  }, [params.id, setScreen]);

  const { setParentTitle: setTitle, setCreatedAt, setActionButtons } = useOutletContext<DetailsOutletContext>();

  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState(photo.llm_summary || '');
  useEffect(() => {
    setTitle('Photo');
    setDescription(photo.llm_summary || '');
    setCreatedAt(photo.uploaded_at);
  }, [photo]);

  const regenerate = async () => {
    setIsRegenerating(true);
    await regeneratePhotoSummary(photo.id);
    setIsRegenerating(false);
  };

  useEffect(() => {
    setActionButtons(
      <Button variant={'secondary'} onClick={regenerate} disabled={isRegenerating}>
        <RefreshCcw className={cn('w-4 h-4 mr-2', isRegenerating && 'animate-spin')} />
        Regenerate
      </Button>
    );

    return () => setActionButtons(null);
  }, [isRegenerating]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col items-center gap-4">
        <img
          src={`/api/files/photo/${photo.uri}`}
          alt="Photo"
          className="h-[480px] object-cover rounded"
        />
        <div className="w-full p-3" onClick={() => setIsEditing(true)}>
          {isEditing ? (
            <Textarea
              className="w-full h-full focus:outline-none"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              onBlur={() => {
                setIsEditing(false);
                // TODO: Save changes to store/backend
              }}
              autoFocus
            />
          ) : description ? (
            <p className="whitespace-pre-wrap">{description}</p>
          ) : (
            <p className="whitespace-pre-wrap text-muted-foreground">Click to add description</p>
          )}
        </div>
      </div>
    </div>
  );
}
