import { Outlet, useLocation } from 'react-router-dom';
import { LinkObservationSheet } from '@/components/explorer/sheets/LinkObservationSheet';
import { CreateObservationSheet } from '@/components/explorer/sheets/CreateObservationSheet';
import { SidebarNav } from '@/components/SidebarNav';
import { useObservationStore } from '@/store/observationStore';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useEffect, useState } from 'react';
import { useSearchStore } from '@/store/searchStore';
import { useAuthStore } from '@/store/authStore';

function LocationListener() {
  const location = useLocation();
  const setLayoutMode = useSearchStore((state) => state.setLayoutMode);

  useEffect(() => {
    setLayoutMode(location.pathname);
  }, [location.pathname, setLayoutMode]);

  return null;
}

function CreateObservationButton({ screen }: { screen: Screen | null }) {
  const { setIsCreating } = useObservationStore();
  const onClick = () => {
    if (!screen) return setIsCreating(true);
    if (screen.screen === 'inspection') setIsCreating(true, screen.id);
    else if (screen.screen === 'project') setIsCreating(true, undefined, screen.id);
    else setIsCreating(true);
  };
  return (
    <div className="fixed bottom-6 right-6">
      <Button
        size="lg"
        onClick={onClick}
        className="rounded-full bg-gray-900 text-gray-50 shadow-lg hover:bg-gray-900/90 focus:outline-none focus:ring-2 focus:ring-gray-950 focus:ring-offset-2 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus:ring-gray-300"
      >
        <Plus className="h-6 w-6 mr-1" />
        <span>Observation</span>
      </Button>
    </div>
  );
}

interface Screen {
  screen: 'photo' | 'voice' | 'inspection' | 'project' | 'observation';
  id: number;
}

export interface DashboardOutletContext {
  setScreen: (screen: Screen | null) => void;
}

export default function Layout() {
  const [screen, setScreen] = useState<Screen | null>(null);

  const userInitialised = useAuthStore((state) => state.userInitialised);

  if (!userInitialised) {
    // Render nothing or a loading indicator until userInitialised is true
    return <div></div>;
  }

  return (
    <div className="flex-grow h-full overflow-y-auto">

      <main className="flex h-full">
        <div className="hidden lg:flex p-4 border-r w-60 h-full flex-shrink-0">
          <SidebarNav />
        </div>
        <div className="flex-grow flex flex-col w-full lg:w-[calc(100vw-15rem)] overflow-auto">
          <Outlet context={{ setScreen }} />
        </div>
      </main>

      <CreateObservationSheet />
      <LinkObservationSheet />
      <CreateObservationButton screen={screen} />
      <LocationListener />
    </div>
  );
}
