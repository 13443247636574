import { Fragment, useEffect } from 'react';
import { useAuthStore } from '@/store/authStore.ts';
import { useOutletContext, Navigate } from 'react-router-dom';
import { DashboardOutletContext } from '@/layouts/Layout';
import { useObservationStore } from '@/store/observationStore';
import { RefreshCcw } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { ExplorerSearchResults } from '@/components/explorer/ExplorerSearchResults'
import { useMediaStore } from '@/store/mediaStore';
import { Skeleton } from '@/components/ui/skeleton.tsx';
import { ExplorerActions } from '../components/explorer/ExplorerActions';
import { ExplorerNavTabs } from '../components/explorer/ExplorerNavTabs';

export default function LayoutTabs() {
  const { setScreen } = useOutletContext<DashboardOutletContext>();

  useEffect(() => {
    setScreen(null);
  }, [setScreen]);

  const { user } = useAuthStore();
  if (!user) return <Navigate to="/login" />;

  const { isLoading: obsLoading, error, retry } = useObservationStore();
  const { isLoading: mediaLoading } = useMediaStore();

  return (
    <Fragment>
      <ExplorerActions />
      <ExplorerNavTabs />
      {obsLoading || mediaLoading ? (
        <Skeleton className="m-4 flex h-8" />
      ) : error ? (
        <div className="flex flex-col items-center mt-4 gap-4 w-full">
          <p className="text-destructive">{error}</p>
          <Button variant="outline" size="sm" onClick={() => retry()} className="flex items-center gap-2">
            <RefreshCcw className="h-4 w-4" />
            Retry
          </Button>
        </div>
      ) : (
        <ExplorerSearchResults />
      )}
    </Fragment>
  );
}
