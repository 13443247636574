import { create } from 'zustand';
import { ItemType } from './selectedItemStore'

interface SearchResult {
  uuid: string;
  id: number;
  uri: string;
  itemType: ItemType;
  type: string;
  title: string;
  description: string;
  createdAt: string;
  sortDate: string;
}

interface SearchResultStore {
  results: SearchResult[];
  paginatedResults: SearchResult[];
  pagination: {
    page: number;
    resultsPerPage: number;
    totalPages: number;
  };
  currentIndex: number;
  setFilteredResults: (data: {
    projects: any[];
    reports: any[];
    inspections: any[];
    observations: any[];
    recordings: any[];
    photos: any[];
  }) => void;
  setPage: (page: number) => void;
  setCurrentItem: (item: SearchResult) => void; 
  navigateToItem: (direction: 'prev' | 'next') => void;
  getCurrentItem: () => SearchResult | null;
  getPreviousItem: () => SearchResult | null;
  getNextItem: () => SearchResult | null;
}

export const useSearchResult = create<SearchResultStore>((set, get) => ({
  results: [],
  paginatedResults: [],
  pagination: {
    page: 1,
    resultsPerPage: 10,
    totalPages: 0,
  },
  currentIndex: 0,

  setFilteredResults: (data) => {
    const { projects, reports, inspections, observations, recordings, photos } = data;

    const combinedResults: SearchResult[] = [
      ...projects.map((item) => ({
        uuid: `Project-${item.id}`,
        id: item.id,
        uri: `/dashboard/project/${item.id}`,
        itemType: 'project' as const,
        type: 'Project',
        title: item.title || 'Project',
        description: item.description || 'No description available',
        createdAt: item.createdAt,
        sortDate: item.createdAt,
      })),
      ...reports.map((item) => ({
        uuid: `Report-${item.id}`,
        id: item.id,
        uri: `/dashboard/report/${item.id}`,
        itemType: 'report' as const,
        type: 'Report',
        title: item.title || 'Report',
        description: item.description || 'No description available',
        createdAt: item.createdAt,
        sortDate: item.createdAt,
      })),
      ...inspections.map((item) => ({
        uuid: `Inspection-${item.id}`,
        id: item.id,
        uri: `/dashboard/inspection/${item.id}`,
        itemType: 'inspection' as const,
        type: 'Inspection',
        title: item.title || 'Inspection',
        description: item.description || 'No description available',
        createdAt: item.createdAt,
        sortDate: item.createdAt,
      })),
      ...observations.map((item) => ({
        uuid: `Observation-${item.id}`,
        id: item.id,
        uri: `/dashboard/observation/${item.id}`,
        itemType: 'observation' as const,
        type: 'Observation',
        title: item.title || 'Observation',
        description: item.description || 'No description available',
        createdAt: item.createdAt,
        sortDate: item.createdAt,
      })),
      ...recordings.map((item) => ({
        uuid: `Recording-${item.id}`,
        id: item.id,
        uri: `/dashboard/voice/${item.id}`,
        itemType: 'recording' as const,
        type: 'Recording',
        title: 'Recording',
        description: item.processedTranscript || 'No description available',
        createdAt: item.uploaded_at,
        sortDate: item.uploaded_at,
      })),
      ...photos.map((item) => ({
        uuid: `Photo-${item.id}`,
        id: item.id,
        uri: `/dashboard/photo/${item.id}`,
        itemType: 'photo' as const,
        type: 'Photo',
        title: 'Photo',
        description: item.llm_summary || 'No description available',
        createdAt: item.uploaded_at,
        sortDate: item.uploaded_at,
      })),
    ];

    // Sort results by sortDate in descending order
    combinedResults.sort((a, b) => new Date(b.sortDate).getTime() - new Date(a.sortDate).getTime());

    const resultsPerPage = get().pagination.resultsPerPage;
    const totalPages = Math.ceil(combinedResults.length / resultsPerPage);

    set(() => ({
      results: combinedResults,
      pagination: {
        page: 1,
        resultsPerPage,
        totalPages
      },
      paginatedResults: combinedResults.slice(0, resultsPerPage),
    }));
  },

  setPage: (page) => {
    const { results, pagination } = get();
    const startIndex = (page - 1) * pagination.resultsPerPage;
    const endIndex = startIndex + pagination.resultsPerPage;

    set(() => ({
      pagination: {
        ...pagination,
        page,
      },
      paginatedResults: results.slice(startIndex, endIndex),
    }));
  },

  setCurrentItem: (item: SearchResult) => {
    const { results } = get();
    const index = results.indexOf(item);
    if (index !== -1) {
      set(() => ({
        currentIndex: index,
      }));
    }
  },

  navigateToItem: (direction) => {
    const { currentIndex, results } = get();
    const newIndex =
      direction === 'next'
        ? Math.min(currentIndex + 1, results.length - 1)
        : Math.max(currentIndex - 1, 0);

    set(() => ({ currentIndex: newIndex }));
  },

  getCurrentItem: () => {
    const { results, currentIndex } = get();
    return results[currentIndex] || null;
  },

  getPreviousItem: () => {
    const { results, currentIndex } = get();
    return results[currentIndex - 1] || null;
  },

  getNextItem: () => {
    const { results, currentIndex } = get();
    return results[currentIndex + 1] || null;
  }
}));
