import { create } from 'zustand';
import { api } from './authStore';
import { toast } from 'sonner';
import { CurrentVoice } from '@/types/observation';
import { nanoid } from 'nanoid';
import { useSearchStore } from './searchStore';
import { useMemo } from 'react';
import { Voice } from '@/types/media';

export const RECORDING_ALIASES = ['files', 'recordings', 'voices', 'auidos']

interface PaginationState {
  total: number;
  page: number;
  limit: number;
  totalPages: number;
}

interface RecordingState {
  recordings: Voice[];
  isLoading: boolean;
  error: string | null;
  pagination: PaginationState | null;
  searchQuery: string;
  searchDebounceTimeout: NodeJS.Timeout | null;
  currentRecordings: CurrentVoice[];
  isRecording: boolean;
  recordingTime: number;
  mediaRecorder: MediaRecorder | null;

  setSearchQuery: (query: string) => void;
  fetchRecordings: (page?: number, search?: string) => Promise<void>;

  startRecording: () => Promise<void>;
  stopRecording: () => void;
  setIsRecording: (isRecording: boolean) => void;
  removeRecording: (recordingId: string) => void;
  uploadRecording: (recordingId: string, blob: Blob) => Promise<void>;
  regenerateTranscriptAndSummary: (remoteRecordingId: number) => void;
}

export const useRecordingStore = create<RecordingState>((set, get) => ({
  recordings: [],
  isLoading: false,
  error: null,
  pagination: null,
  searchQuery: '',
  searchDebounceTimeout: null,
  currentRecordings: [],
  isRecording: false,
  recordingTime: 0,
  mediaRecorder: null,

  setSearchQuery: (query) => {
    set({ searchQuery: query });
    const searchDebounceTimeout = get().searchDebounceTimeout;
    if (searchDebounceTimeout) clearTimeout(searchDebounceTimeout);
    const timeout = setTimeout(() => {
      get().fetchRecordings(1, query);
    }, 300);
    set({ searchDebounceTimeout: timeout });
  },

  fetchRecordings: async () => {
    set({ isLoading: true, error: null });
    try {
      const { searchQuery } = get();
      const response = await api.get('/protected/voice/list', {
        params: {
          query: searchQuery,
        },
      });
      set({
        recordings: response.data.voices,
        pagination: response.data.pagination,
        isLoading: false
      });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred fetching Recordings';
      set({
        error: errorMessage,
        isLoading: false
      });
      toast.error(errorMessage);
    }
  },

  startRecording: async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true
      });
      const mimeType = 'audio/mp4';
      const mediaRecorder = new MediaRecorder(stream, { mimeType });
      let timerInterval: NodeJS.Timeout;

      mediaRecorder.ondataavailable = async (event) => {
        // triggered on end of recording
        if (event.data.size > 0) {
          const blob = new Blob([event.data], { type: mimeType });
          const newRecording = {
            id: nanoid(10),
            audioUrl: URL.createObjectURL(blob),
            transcript: null,
            processedTranscript: null,
            remoteId: null,
            failed: false,
            uploaded_at: new Date()
          } as CurrentVoice;
          set((state) => ({
            currentRecordings: [...state.currentRecordings, newRecording]
          }));
          await get().uploadRecording(newRecording.id, blob);
        }
      };

      mediaRecorder.onstart = () => {
        set({ isRecording: true });
        timerInterval = setInterval(() => {
          set((state) => ({ recordingTime: state.recordingTime + 1 }));
        }, 1000);
      };

      mediaRecorder.onstop = () => {
        set({ isRecording: false, recordingTime: 0 });
        if (timerInterval) clearInterval(timerInterval);
        stream.getTracks().forEach((track) => track.stop());
      };

      set({ mediaRecorder });
      mediaRecorder.start();
    } catch (error) {
      toast('Error starting recording', {
        description: error instanceof Error ? error.toString() : 'Something went wrong'
      });
      console.error('Error starting recording:', error);
    }
  },

  stopRecording: () => {
    const { mediaRecorder } = get();
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      mediaRecorder.stop();
    }
  },

  setIsRecording: (isRecording) => {
    if (isRecording) {
      get().startRecording();
    } else {
      get().stopRecording();
    }
  },

  removeRecording: (recordingId) =>
    set((state) => ({
      currentRecordings: state.currentRecordings.filter((r) => r.id !== recordingId)
    })),

  uploadRecording: async (recordingId, blob) => {
    try {
      const formData = new FormData();
      formData.append('audio', blob);
      const response = await api.post(`/protected/voice/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        maxContentLength: 1000000000,
        maxBodyLength: 1000000000
      });

      set((state) => ({
        currentRecordings: state.currentRecordings.map((r) =>
          r.id === recordingId
            ? {
                ...r,
                transcript: response.data.transcript,
                processedTranscript: response.data.processedTranscript,
                remoteId: response.data.voice_id,
                failed: false
              }
            : r
        )
      }));
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      set((state) => ({
        currentRecordings: state.currentRecordings.map((r) => (r.id === recordingId ? { ...r, failed: true } : r))
      }));
      toast(`Error processing recording`);
    }
  },

  regenerateTranscriptAndSummary: async (remoteRecordingId: number) => {
    try {
      const response = await api.post(`/protected/voice/${remoteRecordingId}/regenerate`);
      // TODO: use a map
      set((state) => ({
        currentRecordings: state.currentRecordings.map((r) =>
          r.remoteId === remoteRecordingId
            ? {
                ...r,
                transcript: response.data.transcript,
                processedTranscript: response.data.processedTranscript,
                failed: false
              }
            : r
        )
      }));
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      set((state) => ({
        currentRecordings: state.currentRecordings.map((r) =>
          r.remoteId === remoteRecordingId
            ? {
                ...r,
                failed: true
              }
            : r
        )
      }));
      toast(`Error regenerating recording`);
    }
  }
}));

export const useFilteredRecordings = () => {
  const { recordings } = useRecordingStore();
  const { filters } = useSearchStore();

  return useMemo(() => {
    const lowerInclude = filters.include?.toLowerCase();
    const lowerExclude = filters.exclude?.toLowerCase();
    const afterDate = filters.after ? new Date(filters.after) : null;
    const beforeDate = filters.before ? new Date(filters.before) : null;
    const filterTab = filters.tab?.toLowerCase();

    return recordings.filter((recording) => {
      const description = recording.processedTranscript?.toLowerCase() ?? '';
      const createdAt = new Date(recording.uploaded_at);

      if (filterTab && filterTab !== '' && !RECORDING_ALIASES.some((alias) => alias.toLowerCase().includes(filterTab))) {
        return false;
      }

      if (lowerInclude && !description.includes(lowerInclude)) {
        return false;
      }

      if (lowerExclude && description.includes(lowerExclude)) {
        return false;
      }

      if (afterDate && createdAt < afterDate) {
        return false;
      }

      if (beforeDate && createdAt > beforeDate) {
        return false;
      }

      return true;
    });
  }, [recordings, filters]); 
  
};