import { Fragment, useCallback, useEffect, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { Pencil } from 'lucide-react';
import { cn } from '@/lib/utils';
import { DashboardOutletContext } from './Layout';
import { ExplorerActions } from '@/components/explorer/ExplorerActions';
import { ExplorerNavBreadcrumbs } from '@/components/explorer/ExplorerNavBreadcrumbs';
// import { ScrollArea } from '@/components/ui/scroll-area';

export interface DetailsOutletContext {
  setParentTitle: (title: string) => void;
  setParentTitleCallback: (onChange: (title: string) => void) => void;
  setCreatedAt: (createdAt: Date) => void;
  setActionButtons: (buttons: React.ReactNode) => void;
  setScreen: (screen: Screen | null) => void;
}

export default function LayoutDetails() {
  const { setScreen } = useOutletContext<DashboardOutletContext>();

  const [title, setTitle] = useState('');
  const [titleCallback, setTitleCallback] = useState<((title: string) => void) | null>(null);
  const [createdAt, setCreatedAt] = useState(new Date());
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [actionButtons, setActionButtons] = useState<React.ReactNode>(null);

  const [saveTimeout, setSaveTimeout] = useState<NodeJS.Timeout | null>(null);

  const debouncedSave = useCallback(
    (newTitle: string) => {
      if (saveTimeout) clearTimeout(saveTimeout);
      const timeout = setTimeout(() => {
        titleCallback?.(newTitle);
      }, 500);
      setSaveTimeout(timeout);
    },
    [titleCallback]
  );

  useEffect(() => {
    return () => {
      if (saveTimeout) clearTimeout(saveTimeout);
    };
  }, [saveTimeout]);

  const handleTitleChange = (newTitle: string) => {
    setTitle(newTitle);
    debouncedSave(newTitle);
  };
  return (
    <Fragment>
      <ExplorerActions />
      <ExplorerNavBreadcrumbs />

      {/* <ScrollArea className="flex-1 h-full"> 
        #ToDo
        SrollArea screwing up when ListItem displayed, ListItem pushed horizontal width too far.
        Wasn't a quick fix so removed for the momonent. 
      */}
        <div className="flex gap-4 px-6 pt-4 justify-between">
          <div>
            <h2
              className={cn('text-lg font-semibold', title === null ? 'text-muted-foreground italic' : '')}
              onClick={() => setIsEditingTitle(true)}
            >
              {isEditingTitle ? (
                <input
                  type="text"
                  value={title}
                  onChange={(e) => handleTitleChange(e.target.value)}
                  onBlur={() => setIsEditingTitle(false)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') setIsEditingTitle(false);
                  }}
                  autoFocus
                  className="border rounded px-2 py-1"
                />
              ) : (
                <span className="flex gap-2 items-center">
                  <Pencil className="h-4 w-4" />
                  {title}
                </span>
              )}
            </h2>
            <div className="mb-4 flex items-center justify-between">
              <p className="text-sm text-muted-foreground">{createdAt.toLocaleString()}</p>
            </div>
          </div>
          <div className="flex items-start gap-2">{actionButtons}</div>
        </div>
        <div className="flex-1 pt-2 px-4 pb-20">
          <Outlet
            context={{
              setParentTitle: setTitle,
              setParentTitleCallback: setTitleCallback,
              setCreatedAt,
              setActionButtons,
              setScreen
            }}
          />
        </div>
      {/* </ScrollArea> */}
    </Fragment>
  );
}
