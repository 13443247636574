import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { useAuthStore } from './store/authStore';
import Login from './components/Login';
import { useEffect } from 'react';
import Layout from '@/layouts/Layout';
import LayoutHeader from '@/layouts/LayoutHeader';
import LayoutTabs from '@/layouts/LayoutTabs';
import LayoutDetails from './layouts/LayoutDetails';
import { SiteInspectionDetails } from './pages/dashboard/details/InspectionDetails';
import { ObservationDetails } from './pages/dashboard/details/ObservationDetails';
import { ProjectDetails } from './pages/dashboard/details/ProjectDetails';
import { PhotoDetails } from './pages/dashboard/details/PhotoDetails';
import { VoiceDetails } from './pages/dashboard/details/VoiceDetails';
import { ReportDetails } from './pages/dashboard/details/ReportDetails';

function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const { user, loading } = useAuthStore();
  if (loading) return <div>Loading...</div>;
  if (!user) return <Navigate to="/login" replace />;
  return <>{children}</>;
}

function App() {
  const { checkAuth } = useAuthStore();

  useEffect(() => {
    checkAuth();
  }, []);

  const router = createBrowserRouter([
    { path: '/login', element: <Login /> },
    {
      path: '/dashboard',
      element: (
        <ProtectedRoute>
          <LayoutHeader />
        </ProtectedRoute>
      ),
      children: [
        {
          element: <Layout />,
          children: [
            { index: true, element: <LayoutTabs /> },
            {
              element: <LayoutDetails />,
              children: [
                { path: 'photo/:id', element: <PhotoDetails /> },
                { path: 'voice/:id', element: <VoiceDetails /> },
                { path: 'inspection/:id', element: <SiteInspectionDetails /> },
                { path: 'project/:id', element: <ProjectDetails /> },
                { path: 'report/:id', element: <ReportDetails /> },
                { path: 'observation/:id', element: <ObservationDetails /> }
              ]
            }
          ]
        }
      ]
    },
    { path: '/', element: <Navigate to="/dashboard" replace /> },
    { path: '*', element: <Navigate to="/dashboard" replace /> }
  ]);

  return <RouterProvider router={router} />;
}

export default App;
