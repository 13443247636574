import { useObservationStore } from '@/store/observationStore';
import { useSelectedItemStore } from '@/store/selectedItemStore';
import { useCallback, useEffect, useState } from 'react';
import { Navigate, useLocation, useOutletContext, useParams } from 'react-router-dom';
import { DetailsOutletContext } from '@/layouts/LayoutDetails';
import { RefreshCcw } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { PhotoEntry, VoiceEntry } from '@/components/explorer/ExplorerEntry';
import { Media, Voice, Photo } from '@/types/media';
import { Textarea } from '@/components/ui/textarea';
import { DashboardOutletContext } from '@/layouts/Layout';
import { useNavigate } from 'react-router-dom';

export function ObservationDetails() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const from = state?.from;
  const { clearSelectedItems } = useSelectedItemStore();
  const { generateSummary, updateDescription, updateTitle } = useObservationStore();
  const params = useParams();
  if (isNaN(Number(params.id))) return <Navigate to="/dashboard" />;
  const observation = useObservationStore((state) => state.observations.find((o) => o.id === Number(params.id)));
  if (!observation) return <Navigate to="/dashboard" />;

  const { setScreen } = useOutletContext<DashboardOutletContext>();

  useEffect(() => {
    setScreen({ screen: 'observation', id: Number(params.id) });
  }, [params.id, setScreen]);

  const { setParentTitle, setParentTitleCallback, setCreatedAt, setActionButtons } =
    useOutletContext<DetailsOutletContext>();

  const [allMedia, setMedia] = useState<Media[]>([]);
  useEffect(() => {
    const remotePhotos = observation.photos.map((p) => ({
      ...p,
      uploaded_at: new Date(p.uploaded_at),
      mediaType: 'photo'
    })) as Media[];
    const remoteVoices = observation.voices.map((v) => ({
      ...v,
      uploaded_at: new Date(v.uploaded_at),
      mediaType: 'voice'
    })) as Media[];
    setMedia(
      [...remotePhotos, ...remoteVoices].sort((a, b) => {
        const dateA = new Date(a.uploaded_at).getTime();
        const dateB = new Date(b.uploaded_at).getTime();
        return dateA - dateB;
      })
    );
  }, [observation.photos, observation.voices]);

  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState(observation.description || '');

  const updateTitleCallback = useCallback(
    (title: string) => {
      updateTitle(observation.id, title);
    },
    [observation.id]
  );

  useEffect(() => {
    setParentTitle(observation.title || `Observation #${observation.id}`);
    setParentTitleCallback(() => updateTitleCallback);
    setDescription(observation.description || '');
    setCreatedAt(new Date(observation.createdAt));
  }, [observation]);

  const [saveTimeout, setSaveTimeout] = useState<NodeJS.Timeout | null>(null);

  const debouncedSave = useCallback(
    (newDescription: string) => {
      if (saveTimeout) clearTimeout(saveTimeout);
      const timeout = setTimeout(() => {
        updateDescription(observation.id, newDescription);
      }, 500);
      setSaveTimeout(timeout);
    },
    [observation.id, updateDescription]
  );
  useEffect(() => {
    return () => {
      if (saveTimeout) clearTimeout(saveTimeout);
    };
  }, [saveTimeout]);

  const handleDescriptionChange = (newDescription: string) => {
    setDescription(newDescription);
    debouncedSave(newDescription);
  };

  const [isRegenerating, setIsRegenerating] = useState(false);
  const regenerate = async () => {
    setIsRegenerating(true);
    await generateSummary(observation.id);
    setIsRegenerating(false);
  };

  useEffect(() => {
    setActionButtons(
      <Button variant={'secondary'} onClick={regenerate} disabled={isRegenerating}>
        <RefreshCcw className={cn('w-4 h-4 mr-2', isRegenerating && 'animate-spin')} />
        Regenerate
      </Button>
    );

    return () => setActionButtons(null);
  }, [isRegenerating]);

  return (
    <div className="flex flex-col gap-2">
      <h3 className="font-semibold">Description</h3>
      <div className="p-3" onClick={() => setIsEditing(true)}>
        {isEditing ? (
          <Textarea
            className="w-full h-full focus:outline-none"
            value={description}
            onChange={(e) => handleDescriptionChange(e.target.value)}
            onBlur={() => setIsEditing(false)}
            autoFocus
          />
        ) : description ? (
          <p className="whitespace-pre-wrap">{description}</p>
        ) : (
          <p className="whitespace-pre-wrap text-muted-foreground">Click to add description</p>
        )}
      </div>
      {allMedia && (
        <div>
          <h3 className="font-semibold mb-2">Attachments</h3>
          <div className="flex flex-col gap-3 mb-36 border rounded-lg">
            {allMedia.length === 0 ? (
              <p className="text-muted-foreground text-sm">No attachments yet</p>
            ) : (
              <ul className="divide-y">
                {allMedia.map((media) => (
                  <li
                    key={media.id}
                    className="py-2 px-4 hover:bg-muted/40 transition-colors cursor-pointer"
                    onClick={() => {
                      navigate(`/dashboard/${media.mediaType}/${media.id}`, {
                        state: { from: [...(from || []), 'Media'] }
                      });
                      clearSelectedItems();
                    }}
                  >
                    {media.mediaType === 'photo' ? (
                      <PhotoEntry photo={media as Photo} />
                    ) : (
                      <VoiceEntry voice={media as Voice} />
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
